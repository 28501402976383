import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../api";
import GreyBtn from "../components/UI/GreyBtn";
import dp from "../image/dp.png";
import styles from "./DashboardPage.module.css";
import classes from "./PurchasePage.module.css";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { FiMenu } from "react-icons/fi";
import DashboardMenu from "../components/dashboard/DashboardMenu";

const PurchasePage = () => {
  const isLoading = useSelector((state) => state.homepage.isLoading);
  const isLoggedIn = useSelector((state) => state.homepage.isLoggedIn);
  const token = useSelector((state) => state.homepage.token);

  const [search, setSearch] = useState("");
  const [data, setData] = useState();
  const [dashboardData, setDashboardData] = useState();
  const [loading, setLoading] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // DASHBOARD DATA
  const dashboardDataFetch = async () => {
    setLoading(true);
    const response = await fetch(`${baseURL}/dashboard`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      setLoading(false);
      return;
    }

    const data = await response.json();

    setDashboardData(data.data);
    setData(data.data.contents);
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      dashboardDataFetch();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (dashboardData) {
      const result = dashboardData.contents.filter((data) =>
        data.title.toLocaleLowerCase().match(search.toLocaleLowerCase())
      );
      setData(result);
    }
  }, [search]);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Price",
      selector: (row) => (row.price ? row.price : 0),
    },
    {
      name: "Author",
      selector: (row) => row.author,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <Link className={styles.link} to={`/content/${row.id}`}>
            <GreyBtn>Details</GreyBtn>
          </Link>
        </>
      ),
    },
  ];

  const navigate = useNavigate();
  if (!isLoggedIn) {
    navigate("/login");
  }

  return (
    <>
      {isLoggedIn && !loading && dashboardData && (
        <div className={styles.container}>
          {/* Add Hamburger Menu Button */}
          <div className={styles.toggleMenu}>
            <button className="primary-icon-button" onClick={toggleMobileMenu}>
              <FiMenu />
            </button>
          </div>

          <div
            className={`${styles.overlay} ${isMobileMenuOpen ? styles.expanded : ''}`}
            onClick={toggleMobileMenu}
          />

          {/* Mobile Menu */}
          <DashboardMenu
            userData={dashboardData.user}
            isMobile={true}
            isExpanded={isMobileMenuOpen}
          />

          {/* Desktop Menu */}
          <DashboardMenu userData={dashboardData.user} />

          <div className={classes.rightCon}>
            <div className={classes.rContainer}>
              <p className={classes.pTitle}>Purchase</p>

              <div className={classes.tableCon}>
                <DataTable
                  data={data}
                  columns={columns}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="500px"
                  highlightOnHover
                  subHeader
                  subHeaderComponent={
                    <input
                      type="text"
                      placeholder="Search"
                      className={classes.tableSearch}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
            marginBottom: "500px",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

export default PurchasePage;
