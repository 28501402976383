import React from "react";
import styles from "./Navbar.module.css";
import logo from "../../image/lonthon_logo.png";
import GreyBtn from "../UI/GreyBtn";
import DropDown from "./DropDown";
import { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { FiMenu } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { homepageActions } from "../../redux/homepage-slice";
import AdSlider from "../UI/AdSlider";

const Navbar = ({ menuItems }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.homepage.isLoggedIn);
  const ad = useSelector((state) => state.homepage.ads.header);

  const toggleMenu = useRef();
  const dropdownRef = useRef();
  const [dropdownStates, setDropdownStates] = useState(
    menuItems ? menuItems.map(() => false) : []
  );

  useEffect(() => {
    if (menuItems) {
      setDropdownStates(menuItems.map(() => false));
      if (toggleMenu.current?.classList.contains("expanded")) {
        toggleMenu.current.classList.remove("expanded");
      }
    }
  }, [location.pathname, menuItems]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest(`.${styles.nav_container}`)
      ) {
        setDropdownStates(menuItems.map(() => false));
        if (toggleMenu.current?.classList.contains("expanded")) {
          toggleMenu.current.classList.remove("expanded");
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuItems]);

  const toggleDropdown = (index, value, event) => {
    if (event) {
      event.stopPropagation();
    }
    setDropdownStates(
      menuItems.map((menu, i) => {
        if (index === i) return value;
        return false;
      })
    );
  };

  const handleToggleMenu = () => {
    toggleMenu.current.classList.toggle("expanded");
  };

  const logoutHandler = () => {
    dispatch(homepageActions.setIsLoggedIn(false));
    dispatch(homepageActions.setToken(""));
    dispatch(homepageActions.setUserData({}));
    localStorage.removeItem("tokenLonthon");
    localStorage.removeItem("userDataLonthon");
  };

  return (
    <>
      {menuItems && (
        <>
          <Wrapper menuSize={menuItems.length}>
            <div>
              <AdSlider ad={ad} />

              <div className={styles.nav_container}>
                <Link className={styles.link} to="/">
                  <div className={styles.logo_container}>
                    <img className={styles.logo} alt="logo" src={logo} />
                    <p>Lonthon</p>
                  </div>
                </Link>
                <div
                  className={`${styles.links} mobile-links`}
                  ref={toggleMenu}
                >
                  {menuItems.map((menu, index) => (
                    <>
                      {!menu.submenu ? (
                        <>
                          {!menu.id && (
                            <Link
                              to={menu.url}
                              className={styles.link}
                              key={index}
                            >
                              <p className={styles.nav_link}>{menu.label}</p>
                            </Link>
                          )}
                        </>
                      ) : (
                        <div
                          key={index}
                          className={styles.lit}
                          ref={dropdownRef}
                          onClick={(e) => toggleDropdown(index, !dropdownStates[index], e)}
                          onMouseEnter={() => toggleDropdown(index, true)}
                          onMouseLeave={() => toggleDropdown(index, false)}
                        >
                          <p className={styles.nav_link}>{menu.label}</p>
                          {dropdownStates && dropdownStates[index] && (
                            <div className={styles.dropDown}>
                              <DropDown
                                links={menu.submenu}
                                catg={menu.id}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ))}

                  {!isLoggedIn && (
                    <>
                      <Link className={styles.link} to="/login">
                        <p className={styles.nav_link}>LOGIN</p>
                      </Link>
                      <Link className={styles.link} to="/signup">
                        <GreyBtn>SIGNUP</GreyBtn>
                      </Link>
                    </>
                  )}

                  {isLoggedIn && (
                    <div onClick={logoutHandler}>
                      <GreyBtn>LOGOUT</GreyBtn>
                    </div>
                  )}
                </div>

                <div className={styles.toggleMenu}>
                  <button
                    className="primary-icon-button"
                    onClick={handleToggleMenu}
                  >
                    <FiMenu />
                  </button>
                </div>
              </div>
            </div>
          </Wrapper>
        </>
      )}
    </>
  );
};
const Wrapper = styled.div`
  .test-class {
    background-color: red;
  }
  .mobile-links {
  }
  .mobile-links.expanded {
    padding: 10px 0px;
    max-height: ${(props) => props.menuSize * 67.78 + 20 + 60 + 60}px;
  }
`;
export default Navbar;
