import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../../pages/DashboardPage.module.css";
import dp from "../../image/dp.png";

const DashboardMenu = ({ userData, isMobile = false, isExpanded = false }) => {
  const location = useLocation();
  const menuClass = isMobile
    ? `${styles.mobileNav} ${isExpanded ? styles.expanded : ''}`
    : styles.leftCon;

  const formattedRole = userData?.role
    ? userData.role.charAt(0).toUpperCase() + userData.role.slice(1)
    : '';

  return (
    <div className={menuClass}>
      <div className={styles.authorCon}>
        <div className={styles.authorSec}>
          <img className={styles.dp} src={dp} alt="author_dp" />
          <div>
            <p className={styles.name}>
              {userData?.first_name || ''} {userData?.last_name || ''}
            </p>
            <p className={styles.role}>{formattedRole}</p>
          </div>
        </div>
        <Link className={styles.link} to="/dashboard/edit_profile">
          <div className={styles.btn}>Edit Profile</div>
        </Link>
      </div>

      <div className={styles.optionsCon}>
        <Link className={styles.link} to="/dashboard">
          <p className={location.pathname === "/dashboard" ? styles.active : styles.options}>
            Dashboard
          </p>
        </Link>

        <Link className={styles.link} to="/dashboard/contact">
          <p className={location.pathname === "/dashboard/contact" ? styles.active : styles.options}>
            Contact
          </p>
        </Link>

        <Link className={styles.link} to="/dashboard/contents">
          <p className={location.pathname === "/dashboard/contents" ? styles.active : styles.options}>
            Contents
          </p>
        </Link>

        <Link className={styles.link} to="/dashboard/withdraw">
          <p className={location.pathname === "/dashboard/withdraw" ? styles.active : styles.options}>
            Withdraw
          </p>
        </Link>

        <Link className={styles.link} to="/dashboard/purchase">
          <p className={location.pathname === "/dashboard/purchase" ? styles.active : styles.options}>
            Purchase
          </p>
        </Link>

        <Link className={styles.link} to="/dashboard/favourites">
          <p className={location.pathname === "/dashboard/favourites" ? styles.active : styles.options}>
            Favourites
          </p>
        </Link>

        <Link className={styles.link} to="/dashboard/negotiation_sent">
          <p className={location.pathname === "/dashboard/negotiation_sent" ? styles.active : styles.options}>
            Negotiation Sent
          </p>
        </Link>

        <Link className={styles.link} to="/dashboard/negotiation_received">
          <p className={location.pathname === "/dashboard/negotiation_received" ? styles.active : styles.options}>
            Negotiation Received
          </p>
        </Link>
      </div>
    </div>
  );
};

export default DashboardMenu;
