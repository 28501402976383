import React, { useState, useEffect } from "react";
import styles from "./DashboardPage.module.css";
import classes from "./EditProfile.module.css";
import dp from "../image/dp.png";
import { Link } from "react-router-dom";
import { FaUserCircle, FaPencilAlt } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import GreyBtn from "../components/UI/GreyBtn";
import { useSelector } from "react-redux";
import { baseURL } from "../api";
import Swal from "sweetalert2";
import DashboardMenu from "../components/dashboard/DashboardMenu";

const EditProfile = () => {
  const isLoggedIn = useSelector((state) => state.homepage.isLoggedIn);
  const token = useSelector((state) => state.homepage.token);

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    contact_no: ''
  });
  const [profilePicture, setProfilePicture] = useState(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/user-info`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        setLoading(false);
        return;
      }

      const result = await response.json();
      if (result.status) {
        setUserData(result.data);
        setProfilePicture(result.data.profile_picture);
        if (!image) {
          setImage(result.data.profile_picture);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserData();
    }
  }, [isLoggedIn]);

  const handleImageUpload = async (file) => {
    console.log("Uploading profile picture");
    try {
      const formData = new FormData();
      formData.append("profile_picture", file);

      const response = await fetch(`${baseURL}/update-profile-picture`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      console.log("Response status:", response.status);

      if (!response.ok) {
        throw new Error('Failed to upload profile picture');
      }

      const result = await response.json();
      console.log("Upload response:", result);

      if (result.status) {
        Swal.fire({
          title: "Updated!",
          text: "Profile picture updated successfully.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
          //  window.location.reload();
          }
        });
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      Swal.fire({
        title: "Error!",
        text: "Failed to upload profile picture. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result);
        handleImageUpload(file);
      };
    }
  };

  const handleSave = () => {
    console.log("Button clicked!");

    const updateProfile = async () => {
      try {
        console.log("Starting update process");
        setLoading(true);

        const requestData = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          contact_no: userData.contact_no,
        };

        console.log("Request data:", requestData);

        const response = await fetch(`${baseURL}/update-user-info`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        });

        console.log("Response status:", response.status);

        if (!response.ok) {
          throw new Error('Failed to update profile');
        }

        const result = await response.json();
        console.log("Response data:", result);

      if (result.status) {
        Swal.fire({
          title: "Updated!",
          text: "Your profile has been updated successfully.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      Swal.fire({
        title: "Error!",
        text: "Failed to update profile. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      console.log("Operation completed");
      setLoading(false);
    }
  };

    updateProfile();
  };

  const testClick = () => {
    console.log('Button clicked!');
  };

  return (
    <div className={styles.container}>
      <div className={styles.toggleMenu}>
        <button className="primary-icon-button" onClick={toggleMobileMenu}>
          <FiMenu />
        </button>
      </div>

      <div
        className={`${styles.overlay} ${isMobileMenuOpen ? styles.expanded : ''}`}
        onClick={toggleMobileMenu}
      />

      <DashboardMenu
        userData={userData}
        isMobile={true}
        isExpanded={isMobileMenuOpen}
      />

      <DashboardMenu userData={userData} />

      <div className={classes.rightCon}>
        <div className={classes.rContainer}>
          <p className={classes.epTitle}>Edit Profile</p>
          <div className={classes.userImgCon}>
            {!image && !profilePicture && (
              <FaUserCircle
                className={classes.userIcon}
                onClick={() => document.querySelector(".input_img").click()}
              />
            )}
            {(image || profilePicture) && (
              <img
                src={image || profilePicture}
                className={classes.dp}
                alt="uploaded"
                onClick={() => document.querySelector(".input_img").click()}
              />
            )}
            <FaPencilAlt
              className={classes.pencilIcon}
              onClick={() => document.querySelector(".input_img").click()}
            />
            <input
              type="file"
              accept="image/*"
              className="input_img"
              hidden
              onChange={handleImageChange}
            />
          </div>
          <div className={classes.formSection}>
            <div className={classes.formTitle}>First Name</div>
            <input
              className={classes.inputText}
              type="text"
              value={userData.first_name}
              onChange={(e) => setUserData({...userData, first_name: e.target.value})}
            />

            <div className={classes.formTitle}>Last Name</div>
            <input
              className={classes.inputText}
              type="text"
              value={userData.last_name}
              onChange={(e) => setUserData({...userData, last_name: e.target.value})}
            />

            <div className={classes.formTitle}>Email</div>
            <input
              className={classes.inputText}
              type="email"
              value={userData.email}
              disabled
            />

            <div className={classes.formTitle}>Contact Number</div>
            <input
              className={classes.inputText}
              type="text"
              value={userData.contact_no}
              onChange={(e) => setUserData({...userData, contact_no: e.target.value})}
            />

            <div className={classes.btn}>
              <button className={classes.saveButton} onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
