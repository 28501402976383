import React, { useState, useEffect } from "react";
import styles from "./DashboardPage.module.css";
import InfoCard from "../components/UI/InfoCard";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../api";
import { Box, CircularProgress } from "@mui/material";
import { homepageActions } from "../redux/homepage-slice";
import { FiMenu } from "react-icons/fi";
import DashboardMenu from "../components/dashboard/DashboardMenu";

const DashboardPage = () => {
  const isLoggedIn = useSelector((state) => state.homepage.isLoggedIn);
  const token = useSelector((state) => state.homepage.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const dashboardDataFetch = async () => {
    setLoading(true);
    const response = await fetch(`${baseURL}/dashboard`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      setLoading(false);
      return;
    }

    const data = await response.json();
    setDashboardData(data.data);
    dispatch(homepageActions.setDashboardData(data.data));
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      dashboardDataFetch();
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    navigate("/login");
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      {isLoggedIn && !loading && dashboardData && (
        <div className={styles.container}>
          <div className={styles.toggleMenu}>
            <button className="primary-icon-button" onClick={toggleMobileMenu}>
              <FiMenu />
            </button>
          </div>

          <div
            className={`${styles.overlay} ${isMobileMenuOpen ? styles.expanded : ''}`}
            onClick={toggleMobileMenu}
          />

          {/* Mobile Menu */}
          <DashboardMenu
            userData={dashboardData.user}
            isMobile={true}
            isExpanded={isMobileMenuOpen}
          />

          {/* Desktop Menu */}
          <DashboardMenu userData={dashboardData.user} />

          <div className={styles.rightCon}>
            <div className={styles.dashCon}>
              <p className={styles.title}>Dashboard</p>
              <div className={styles.info}>
                <InfoCard
                  title="Total Contents"
                  amount={dashboardData.total_contents}
                  sign=""
                />
                <InfoCard
                  title="Balance"
                  amount={dashboardData.balance}
                  sign="BDT"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "500px",
        }}>
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

export default DashboardPage;
